import styled from "styled-components";


const SocialsContainer = styled.div`
    grid-column: 11 / 13;
    grid-row: 1 / 3;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    > :not(:first-child):not(:last-child) {
        margin: 0 ${props=>props.theme.space02}; 
    }

    a {
        height: ${props=>props.theme.space03};
    }

    @media only screen and (max-width: 855px) {
    
        grid-column: 8 / 10;
        
    }

    @media only screen and (max-width: 650px) {
        grid-column: 1/10;
        grid-row: 3/4;
        align-items: center;
        justify-content: center;

        svg {
            width: 24px;
        }
        
    }

    
`;

export const Socials = ({socials}) => (
    <SocialsContainer>
        {socials.map((social, index)=><a key={index} href={social.href}>{[social.component]}</a>)}
  
    </SocialsContainer>
);
