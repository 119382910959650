import styled from "styled-components";

const ContentContainer = styled.div`
    height: calc(100% - ${props=>props.theme.space08});
    max-width: 1136px;
    max-height: 656px;
    margin: 0 auto;

    display: grid;
    /*grid-template-columns: repeat(12, ${props=>props.theme.space10});*/
    grid-template-columns: repeat(12, 1fr);
    column-gap: ${props=>props.theme.space02};

    grid-template-rows: repeat(7, 1fr);
    row-gap: ${props=>props.theme.space02};

    font-family: arial;

    @media only screen and (min-width: 1136px){
        width: 1136px;

    }

    @media only screen and (max-width: 1135px) {
    
      
        grid-template-columns: repeat(12, 64px);
        grid-template-rows: repeat(7, 64px);
    }

    @media only screen and (max-width: 966px) {
    
      
        grid-template-columns: repeat(12, 56px);
        grid-template-rows: repeat(7, 56px);
    }

    @media only screen and (max-width: 855px) {
    
        grid-template-columns: repeat(9, 56px);
        grid-template-rows: repeat(9, 56px);
      
        
    }

    @media only screen and (max-width: 650px) {
    
        grid-template-columns: repeat(9, 1fr);
        grid-template-rows: repeat(9, 1fr);
        column-gap: 0;
        row-gap: 0;
        
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Content = ({children}) =>(
    <ContentWrapper>
        <ContentContainer>
            {children}
        </ContentContainer>
    </ContentWrapper>
)