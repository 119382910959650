import styled from "styled-components";
import profile from "../img/profile.jpeg";

const ProfileContainer = styled.div`
    grid-column: 1 / 3;
    grid-row: 1 / 3;

    display: flex;
    align-items: center;

    @media only screen and (max-width: 855px) {
    
        grid-column: 1/3;
        
    }

    @media only screen and (max-width: 650px) {
    
        grid-column: 2/9;
        grid-row: 1/2;

        width: 144px;
        height: 144px;

        margin: 0 auto;
        
    }
    
`;

const ProfilePicture = styled.div`
    width: 80%;
    height: 80%;

    img {
        width: 100%;
        height: 100%;
        padding: ${props=>props.theme.space01};
        background: ${props=>props.theme.primary};
        border-radius: 90%;

    }
`;

export const Profile = () => (
    <ProfileContainer>
        <ProfilePicture>
            <img src={profile} alt="Profile"/>
        </ProfilePicture>
    </ProfileContainer>
);