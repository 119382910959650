import styled from "styled-components";

// const ArtContainer = styled.div`
//     grid-column: 1 / 7;
//     grid-row: 3 / 7;
//     background: black;
// `
import giraffe_white from "../img/giraffe_white.svg";
import giraffe_brown from "../img/giraffe_brown.svg";

function randomElement(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
}

const ColorBlock = styled.div`
    grid-column: ${props=>`${props.colStart} / ${props.colStart + 1}`};
    grid-row: ${props=>`${props.rowStart} / ${props.rowStart + 1}`};
    background: ${props=>props.theme[props.color]};
    background-image: url(${props=>props.giraffeColor === 'white' ? giraffe_white: giraffe_brown});

    /*background-size: contain;
    background-position: center;
    background-repeat: no-repeat;*/

    transform: rotate(${props=>props.rotate}deg);

    @media only screen and (max-width: 966px) {
        ${props=>(props.colStart > 4) && `display: none;`}
      
    }
 
    @media only screen and (max-width: 855px) {
        ${props=>(props.rowStart !== 3) && `display: none;`}
        grid-column: ${props=>`${props.colStart + 4 } / ${props.colStart + 5}`};
        grid-row: ${props=>`${props.rowStart } / ${props.rowStart + 1}`};

        display: none;
    }
`;

const colorSet = ['primary', 'secondary', 'dark', 'grey'];
const giraffeColors = ['white', 'brown'];
const degrees = [0, 90, 180, 270];

let colors = [];

for(let i = 1; i < 6; i ++){
    for (let j = 3; j < 8; j++){
        let randColor = randomElement(colorSet)
        let randGiraffe = randomElement(giraffeColors);
        let randRotate = randomElement(degrees);

        colors.push(<ColorBlock colStart={i} rowStart={j} color={randColor} giraffeColor={randGiraffe} rotate={randRotate}/>);
    }
}

export const Art = () => (
    colors
    
)