/* just change the values for the sizing of the grid blocks in the media queries */


import './App.css';

import {ThemeProvider} from "styled-components";
import theme from './components/theme';
import { Navbar } from './components/Navbar';
import { Content } from './components/Content';
import { Profile } from './components/Profile';
import { TitleSection } from './components/TitleSection';
import { Socials } from './components/Socials';
import { About } from './components/About';
import { Art } from './components/Art';
import {about, socials, name, quote} from "./information";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Navbar/>
        <Content>
          <Profile/>
          <TitleSection title={name} subtitle={quote}/>
          <Socials socials={socials}/>
          <About info={about}/>
          <Art/>
        </Content>
      </ThemeProvider>
    </div>
  );
}

export default App;
