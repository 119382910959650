import styled from "styled-components";
import { HeaderTitle, Title } from "./Base";

export const TitleContainer = styled.div`
    grid-column: 3 / 8;
    grid-row: 1 / 3;
    display: flex;
    align-items: center;
   
    @media only screen and (max-width: 855px) {
    
        grid-row: 1 / 3;
        grid-column: 3 / 8;
        
    }

    @media only screen and (max-width: 650px) {
    
        grid-row: 2/3;
        grid-column: 2/9;

        justify-content: center;

        div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
        }

        ${HeaderTitle} {
            font-size: 16px;
            line-height: 24px;
        }
        
    }
`;

export const TitleSection = ({title, subtitle}) => (
    <TitleContainer>
        <div>
            <Title>{title}</Title>
            <HeaderTitle>{subtitle}</HeaderTitle>
        </div>
    </TitleContainer>
)