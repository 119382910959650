import styled from "styled-components";

export const Title = styled.div`
    font-size: ${props=>props.theme.space04};
    color: ${props=>props.theme.primary};
    line-height: ${props=>props.theme.space04};
`;

export const Subtitle = styled.div`
    font-size: calc(${props=>props.theme.space03} - 4px);
    line-height: ${props=>props.theme.space03};
    color: ${props=>props.theme.primary};
`;


export const HeaderTitle = styled.div`
    font-size: ${props=>props.theme.space03};
    line-height: ${props=>props.theme.space05};
    color: ${props=>props.theme.grey};
`;

export const Underline = styled.div`
    width: 100%;
    height: ${props=>props.theme.space01};
    background-color: ${props=>props.theme.grey};
`;

export const Body = styled.div`
    font-size: calc(${props=>props.theme.space02} - 2px);
    line-height: ${props=>props.theme.space03};
    color: ${props=>props.theme.dark};
`;

export const Link = styled.a`
    color: ${props=>props.theme.secondary};
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;