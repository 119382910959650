import styled from "styled-components";
import { Subtitle, Underline, Body} from "./Base";

const AboutContainer = styled.div`
    grid-column: 7 / 13;
    grid-row: 3 / 8;

    ${Body} {
        margin: ${props=>props.theme.space02} 0;
    }

    @media only screen and (max-width: 966px) {
        grid-column: 6 / 13;
      
    }

    @media only screen and (max-width: 855px) {
        grid-column: 1 / 10;
        grid-row: 3 / 10;
    }

    @media only screen and (max-width: 650px) {
    
        grid-column: 2 / 9;
        grid-row: 4/10;
        
    }

   
`;

export const About = ({info}) => (
    <AboutContainer>
    
        <Subtitle>About Me</Subtitle>
        <Underline/>

        {info.map((content, index)=><Body key={index}>{content}</Body>)}

    
    </AboutContainer>
)