const theme =  {
    grey: "#c0d1e0",
    dark: "#072946",
    light: "#f4faff",
    primary: "#074f8e",
    secondary: "#7bc2ff",

    space01: "8px",
    space02: "16px",
    space03: "24px",
    space04: "32px",
    space05: "40px",
    space06: "48px",
    space07: "56px",
    space08: "64px",
    space09: "72px",
    space10: "80px",

    
};

export default theme;