import { Link } from "./components/Base";

import {ReactComponent as Twitter} from "./img/twitter.svg";
import {ReactComponent as Linkedin} from './img/linkedin.svg';
import {ReactComponent as Mail} from "./img/mail.svg";

// List of paragraphs that contain a list of docs
export const about = [

    ["I am a first year PhD student at Stanford University in the Computer Science Department. I am grateful to be supported by the ", 
    <Link href="https://knight-hennessy.stanford.edu/program/scholars/2021/jordan-troutman">Knight-Hennessy Scholarship</Link>, 
    ", the ",
    <Link href="https://www.nsfgrfp.org/">National Science Foundation Graduate Research Fellowship</Link>, 
    ", and the ",
    <Link href="https://vpge.stanford.edu/fellowships-funding/enhancing-diversity-graduate/details">Stanford EDGE Fellowship</Link>,
    " (+ the love and support from family and friends)." 
    ],

    [`
    I generally am curious about understanding how technology affects how we garner information about the world, 
    and how we furthermore make decisions with that knowledge. Currently, I am interested in topics focusing on machine learning, 
    network propagation, and computational social science.
    `],

    ["Before coming to California, I lived in Maryland for my whole life. I graduated from ",
    <Link href="https://news.umbc.edu/umbcs-jordan-troutman-to-continue-algorithmic-fairness-research-as-knight-hennessy-scholar-at-stanford/">The University of Maryland, Baltimore County (UMBC)</Link>,
    " where I obtained degrees in Computer Science and Mathematics. I also was a ",
    <Link href="https://chanzuckerberg.com/blog/three-gen-zs-who-will-change-the-world/">Meyerhoff Scholar (M29)</Link>, 
    " and a member of the Honors College."],

   [`My research inquiry has been inspired by the experiences I’ve had working with Prof. Anand Sarwate (Rutgers), 
   Prof. James Foulds (UMBC), Prof. Moritz Hardt (U.C. Berkeley), Dr. Miro Dudik, and Dr. Solon Barocas (Microsoft Research, NYC).`]

];

export const socials = [
    {
        name: "linkedin",
        component: <Linkedin height={24}/>,
        href: "https://www.linkedin.com/in/jordantroutman/"
    },

    {
        name: "twitter",
        component: <Twitter height={24}/>,
        href: "https://twitter.com/jordantroutboy"
    },

    {
        name: "mail",
        component: <Mail height={24}/>,
        href: "mailto:jordantroutman1.0@gmail.com",
    }

]

export const name ="Jordan Troutman";

export const quote=`"Begin with the end in mind"`;



